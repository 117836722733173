import { Form, InputGroup } from "react-bootstrap";
import styled from "styled-components";

export const FormLabel = styled(Form.Label)`
    margin-left: .375rem;
    margin-bottom: .275rem;  
    font-size: .785rem;
    font-weight: bold;
`

export const FormFeedback = styled(Form.Control.Feedback)`
    margin: 0;
    font-size: .785rem;
    font-weight: 500;
`

export const GroupText = styled(InputGroup.Text)`
    width: 25px;
    display: flex;
    justify-content: center;
`