import { forwardRef, useImperativeHandle, useState } from "react";
import { BsDataTable } from "../components/BsDataTable";
import { Column } from "primereact/column";
import { MobVersion } from "../utils";
import styled from "styled-components";
import { useTheme } from "@mui/material";
import { BsCard } from "../components/BsCard";
import { ButtonDataTable } from "../new_components/Button";

const Wrapper = styled.div`
    padding: 1rem;
    padding-top: 0;
    margin-top: -10px;
    border-radius: 5px;
`

interface DataTableChartProps {
    values?: any;
    title: string;
    icon: any;
    columns: any[];
}

const dataTableButtons = (row: any, adicionalFunctions?: any) => {
    return (
        <div style={{ alignItems: 'center', display: 'flex' }}>
            {
                adicionalFunctions?.map((button: any, i: number) => (
                    <ButtonDataTable key={i} color={button.color} iconcenter={button.icon} onClick={() => button.onClick(row)} />
                ))
            }
        </div>
    );
}

export const DataTableChart = forwardRef((props: DataTableChartProps, ref) => {
    const theme = useTheme();

    const [data, setData] = useState<any>([]);

    useImperativeHandle(ref, () => ({
        setData
    }));

    return (
        <BsCard icon={props.icon} title={props.title} style={{ backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff' }}>
            <Wrapper>
                <BsDataTable value={props.values ? props.values : data} paginator={props.values ? props.values.length > 0 : data.length > 0} rows={10} >
                    {
                        props.columns?.map((col: any, i: number) => (
                            <Column
                                key={i}
                                field={col.field}
                                header={col.header}
                                align={!MobVersion() ? 'left' : 'right'}
                                body={col.field === 'edit' ? (e) => dataTableButtons(e, col.adicionalFunctions) : col.body}
                                style={{ fontSize: '12px', marginBottom: '-5px', ...col.style }}
                            />
                        ))
                    }
                </BsDataTable>
            </Wrapper>
        </BsCard>
    )
})