import styled from "styled-components"
import { Card } from '@mui/material';
import { COLOR_THEME } from "../../styles/theme";
import { MobVersion } from "../../utils";

const Container = styled.div`
    position: relative;
    display: flex;
    margin-top: 25px;
    align-items: center;
    justify-content: center;
`
const Wrapper = styled.div`
    position: absolute;
    display: flex;
    height: 50px;
    width: calc(100% - 30px);
    top: -20px;
    background-color: ${COLOR_THEME};
    border-radius: 5px;
    align-items: center;
    justify-content: space-between; 
`
const WrapperTitle = styled.div`
    display: flex;
    align-items: center;
`
const Icon = styled.div`
    display: flex;
    color: #fff;
    margin-left: 15px;
    
`
const Title = styled.div`
    display: flex;
    color: #fff;
    margin-left: 10px;
    font-size: 16px;
    font-weight: 600;
`
const SubTitle = styled(Title)`
    font-size: 11px;
    font-weight: 100;
`

export const BsCard = (props: any) => {
    return (
        <Container>
            <Wrapper>
                <WrapperTitle>
                    <Icon>{props.icon}</Icon>
                    <div>
                        <Title>{props.title} </Title>
                        <SubTitle>{props.subtitle}</SubTitle>
                    </div>
                </WrapperTitle>
            </Wrapper>
            <Card
                style={{
                    ...props.style,
                    height: props.height,
                    width: '100%',
                    padding: props.padding,
                    border: '1px solid rgba(150, 150, 150, 0.35)',
                    paddingTop: MobVersion() ? '35px' : '50px'
                }}
            >
                {props.children}
            </Card>
        </Container>
    )
}       
