
import React, { useContext, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { BsTitle } from '../BsHeader'
import { Column, ColumnBodyType } from 'primereact/column';
import { findIndexById, formatDataCompare, MobVersion } from '../../utils';
import { AuthContext } from '../../contexts/AuthProvider';
import { BsConfirmation } from '../BsConfirmation';
import { BsDataTable } from '../BsDataTable';
import { HeaderTelaPadrao } from './Header';
import { ScrollPanel } from 'primereact/scrollpanel';
import { BsCadastroPadrao } from '../BsCadastroPadrao';
import { ExportPDF } from '../ExportPDF';
import { toast } from 'react-toastify';
import { ButtonDataTable } from '../../new_components/Button';
import { RiPencilFill } from "react-icons/ri";
import { FaCheck } from "react-icons/fa6";
import { CgClose } from "react-icons/cg";
import { MdDelete } from "react-icons/md";

export interface BsColumns {
    field?: string;
    header?: string;
    sortable?: boolean;
    exportable?: boolean;
    body?: ColumnBodyType;
    style?: React.CSSProperties;
    adicionalFunctions?: Array<any>;
    customFilter?: any;
    customHeader?: () => JSX.Element;
}

export const BsTelaPadrao = React.forwardRef((props: any, ref: any) => {

    const auth = useContext(AuthContext);

    const refHeader = useRef<any>(null);
    const refCadastro = useRef<any>(null);
    const confirmationRef = useRef<any>(null);

    const [loadRegister, setLoadRegister] = useState(false);
    const [register, setRegister] = useState(props.model.base);
    const [registers, setRegisters] = useState<any>([]);
    const [filteredRegisters, setFilteredRegisters] = useState<any>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [loading, setLoading] = useState(false);
    const [showAtivos, setShowAtivos] = useState(true);
    const [showInativos, setShowInativos] = useState(false);
    const [msgConfirmation, setMsgConfirmation] = useState('');
    const [functionConfirmation, setFunctionConfirmation] = useState<any>({});
    const [warningConfirmation, setWarningConfirmation] = useState(false);
    const [selected, setSelected] = useState(null);
    const [refreshFilter, setRefreshFilter] = useState(false);

    function SetValueRegisters(values: any) {
        setRegisters(values);
    }

    // Requisição de consulta na API

    async function consultaApi() {
        setLoading(true);
        SetValueRegisters([]);

        async function fetchData() {
            await auth.requestGet(props.model.apiConsulta).then((response: any) => {
                setLoading(false);

                if (process.env.NODE_ENV === 'development')
                    console.log('Consulta ' + props.model.module, response.data.dados);

                SetValueRegisters(response.data.dados);
                setSelected(null);
            }).catch((error: any) => {
                toast.error(error?.response?.data?.mensagem ? error?.response?.data?.mensagem : 'Erro ao concluir a operação.');
                setLoading(false);
            })
        }

        fetchData();
    };

    function renderize() {
        var _filteredRegisters = filteredRegisters;
        setFilteredRegisters(_filteredRegisters)

    }

    function getRegisters() {
        return filteredRegisters
    }

    function getSelecteds() {
        return selected
    }

    useImperativeHandle(ref, () => ({
        refresh: consultaApi,
        renderize,
        getRegisters,
        getSelecteds,
        setRefreshFilter
    }));

    // Requisição de cancelamento ou exclusão de registros 

    async function cancelOrDelete(listRegister: any[], register: any) {
        const data = { 'contador': + register.contador };

        async function execute() {
            try {
                if (props.prefixApiDelete) {
                    await auth.requestDelete(`${props.prefixApiDelete}`, data)
                    const index = findIndexById(registers, register.contador);
                    listRegister.splice(index, 1);
                    SetValueRegisters(listRegister);
                } else {
                    await auth.requestPost(`${props.cancel}`, data);
                    consultaApi();
                }

                setRegister(props.model.base);
                toast.success(`Registro ${props.prefixApiDelete ? `excluído` : `cancelado`} com sucesso.`);
            } catch (error: any) {
                toast.error(error?.response?.data?.mensagem ? error?.response?.data?.mensagem : 'Erro ao concluir a operação.');
                setLoading(false);
            }
        }

        await execute();
    }

    // Ações ao clicar em "Novo", "Editar", "Excluir" e "Ativar/Inativar"

    function novoClick() {
        setRegister(props.model.base);
        refCadastro.current.openModal();
    }

    function editarClick(rowData: any) {
        var returnFuncion: any = {
            sucess: true,
            message: ''
        }

        if (props.onBeforeEdit)
            returnFuncion = props.onBeforeEdit(rowData);

        if (!returnFuncion.sucess)
            toast.error(returnFuncion.message);
        else {
            setRegister({ ...rowData });
            refCadastro.current.openModal();
        }
    }

    function inativarClick(_register: any) {
        async function accept() {
            _register.ativo = _register.ativo === 0 ? 1 : 0;
            await refCadastro.current.upsert(_register, 'Registro ' + (_register.ativo === 1 ? 'ativado' : 'inativado') + ' com sucesso.');
        }

        setFunctionConfirmation(() => accept);
        setMsgConfirmation(`Deseja mesmo ${_register.ativo === 0 ? `ativar` : `inativar`} o registro selecionado ?`);
        setWarningConfirmation(_register.ativo === 1);
        confirmationRef.current.openModal();
    };

    function excluirClick(register: any) {
        async function accept() {
            let _listRegisters = [...registers];
            await cancelOrDelete(_listRegisters, register);
        }

        setFunctionConfirmation(() => accept);
        setMsgConfirmation(`Deseja mesmo ${props.prefixApiDelete ? `excluir` : `cancelar`} o registro selecionado?`);
        setWarningConfirmation(true);
        confirmationRef.current.openModal();
    };

    // Filtro

    const filterInativos = (value: any) => {
        if (value.ativo !== undefined)
            return (showAtivos && String(value.ativo).includes('1')) || (showInativos && String(value.ativo).includes('0'))
        else
            return value;
    }

    const filter = (value: any) => {
        var result = true;

        if (!props.filterInputs || !refHeader.current || (props.filterInputs.length === 0))
            return true
        else {
            let refs = refHeader.current.getRefsFilter();

            if (refs.current) {
                for (const inp of props.filterInputs) {
                    let _inpRef = refs.current[inp.id];
                    let _values = _inpRef.getValue();

                    if ((inp.type === 'date') && (_values !== '') && (_values !== 'Invalid date')) {
                        if (inp.modeFilter === 'initial')
                            result = result && (formatDataCompare(value[inp.id]) >= formatDataCompare(_values))
                        else if (inp.modeFilter === 'final')
                            result = result && (formatDataCompare(value[inp.id]) <= formatDataCompare(_values))
                    } else if ((inp.type === 'select') && (_values && _values.length > 0)) {
                        if (inp.id.split(".").length > 1) {
                            let _valueSplit = value[inp.id.split(".")[0]][inp.id.split(".")[1]];
                            result = result && _valueSplit.contador && _values.some((itemValue: any) => itemValue.contador === _valueSplit.contador)
                        } else
                            result = result && value[inp.id].contador && _values.some((itemValue: any) => itemValue.contador === value[inp.id].contador)
                    }
                }
            } else
                return true
        }

        return result
    }

    function applyFilter() {
        return registers?.filter(
            (value: any) => filterInativos(value) && (!globalFilter || props.onFilterRegisters(value, globalFilter)) && filter(value) && (!props.customFilter || props.customFilter(value))
        )
    }

    useMemo(() => {
        setFilteredRegisters(applyFilter());
        setRefreshFilter(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registers, globalFilter, showInativos, showAtivos, refreshFilter])

    // Funções do DataTable
    const dataTableButtons = (rowData: any, adicionalFunctions?: any) => {
        return (
            <div style={{ alignItems: 'center', display: 'flex' }}>
                {
                    adicionalFunctions?.map((button: any, i: number) => (
                        <ButtonDataTable key={i} iconcenter={button.icon} color={button.color} onClick={() => button.onClick(rowData, setRegister(rowData))} />
                    ))
                }
                <ButtonDataTable
                    visible={!props?.disabledEdit}
                    iconcenter={<RiPencilFill />}
                    color={'#2196F3'}
                    onClick={() => editarClick(rowData)}
                />
                <ButtonDataTable
                    visible={!props.disabledInativar && (rowData.ativo !== undefined)}
                    iconcenter={rowData.ativo === 0 ? <FaCheck /> : <CgClose />}
                    color={rowData.ativo === 0 ? '#22C55E' : '#FFAC30'}
                    onClick={() => inativarClick(rowData)}
                />
                <ButtonDataTable
                    visible={((props.prefixApiDelete !== undefined) || (props.cancel !== undefined))}
                    iconcenter={props.prefixApiDelete ? <MdDelete /> : <CgClose />}
                    color='#DC2626'
                    onClick={() => excluirClick(rowData)} />
            </div>
        );
    }


    // DataTable Detalhe

    const [expandedRows, setExpandedRows] = useState([]);

    const dataTableDetail = (data: any) => {
        return (
            <div style={{ width: '100%', marginTop: `${!MobVersion() ? '-9px' : '0'}`, marginBottom: `${!MobVersion() ? '-9px' : '0'}` }}>
                <BsDataTable value={[data]} >
                    {
                        props.columnsExpanded?.map((col: any, i: number) => (
                            <Column
                                key={i}
                                field={col.field}
                                header={col.header}
                                body={col.body}
                                align={!MobVersion() ? 'left' : 'right'}
                                style={{ fontSize: '12px', marginBottom: '-5px', ...col.style }}
                            />
                        ))
                    }
                </BsDataTable>
            </div>
        );
    };

    function clearFilter(refs: any) {
        if (props.filterInputs && (registers.length > 0)) {

            for (const inp of props.filterInputs) {
                let _inpRef = refs.current[inp.id];
                _inpRef.clearValue();
            }

            setTimeout(() => {
                setFilteredRegisters(applyFilter());
            }, 0);
        }
    }

    if (!loadRegister) {
        consultaApi();
        setLoadRegister(true);
    }

    return (
        <div style={{ height: 'calc(100vh - 65px)', display: 'flex', flexDirection: 'column' }}>
            <BsConfirmation
                ref={confirmationRef}
                confirmClick={() => functionConfirmation()}
                message={msgConfirmation}
                confirmAlert={warningConfirmation}
            />

            <BsTitle title={props.module ? props.module : props.model.module} />

            <div style={{ flex: '1', overflow: 'hidden' }}>
                <ScrollPanel style={{ height: '100%', overflow: 'auto' }}>
                    <BsDataTable
                        header={
                            <HeaderTelaPadrao
                                ref={refHeader}
                                filter={globalFilter}
                                changeFilter={(filter: string) => setGlobalFilter(filter)}
                                register={register}
                                novoVisible={props.model.inputs.length > 0}
                                novoClick={novoClick}
                                refreshClick={() => consultaApi()}
                                changeSwitchInativo={(e: any) => setShowInativos(e)}
                                changeSwitchAtivo={(e: any) => setShowAtivos(e)}
                                adicionalFunctions={props.adicionalFunctions}
                                filterInputs={props.filterInputs}
                                applyFilter={() => setFilteredRegisters(applyFilter())}
                                clearFilter={(refs: any) => clearFilter(refs)}
                                customHeader={props.customHeader}
                                exportToExcel={() => ExportPDF((props.module ? props.module : props.model.module), filteredRegisters, props.columns, auth.name)}
                            />
                        }
                        paginator={filteredRegisters.length > 0}
                        scrollHeight={'100%'}
                        sortField={props.sortcolumn}
                        sortOrder={props.sortcolumnorder}
                        value={filteredRegisters}
                        loading={loading}
                        expandedRows={expandedRows}
                        onRowToggle={(e: any) => setExpandedRows(e.data)}
                        rowExpansionTemplate={dataTableDetail}
                        rowClassName={props.rowClass}
                        rowGroupMode={props.groupcolumn ? "subheader" : undefined}
                        groupRowsBy={props.groupcolumn}
                        rowGroupFooterTemplate={props.groupfooter}
                        rowGroupHeaderTemplate={props.groupheader}
                        selectionMode={props.columnChecked ? 'checkbox' : undefined}
                        selection={selected}
                        onSelectionChange={(e) => setSelected(e.value)}
                    >
                        {props.columnChecked && <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} ></Column>}
                        {props.columnsExpanded && <Column expander={true} style={{ fontSize: '12px', width: '2.5rem', marginBottom: '-10px' }} />}
                        {
                            props.columns?.map((col: any, i: number) => (
                                <Column
                                    key={i}
                                    field={col.field}
                                    header={col.header}
                                    align={!MobVersion() ? 'left' : 'right'}
                                    sortable={col.sortable}
                                    body={col.field === 'edit' ? (e) => dataTableButtons(e, col.adicionalFunctions) : col.body}
                                    style={{ ...col.style, fontSize: '12px', marginBottom: `${(i + 1) === props.columns?.length ? '0px' : '-14px'}` }}
                                />
                            ))
                        }
                    </BsDataTable>
                </ScrollPanel>
            </div>

            <BsCadastroPadrao
                ref={refCadastro}
                register={register}
                model={props?.model}
                title={(register.contador ? "Editar - " : "Novo - ") + props.model.title}
                prefixApiCadastro={props.model.apiCadastro}
                onAfterConfirm={() => consultaApi()}
                headerDialog={props.headerDialog}
                sizeDialog={props.model.sizeDialog ? props.model.sizeDialog : props.sizeDialog}
            />

        </div >

    )
});