import React, { useImperativeHandle, useRef, useState } from 'react';
import { BsDialog } from '../../../../components/BsDialog';
import { Grid } from '@mui/material';
import { Inputs } from '../../../../components/Inputs';
import { MobVersion } from '../../../../utils';
import { useAuth } from '../../../../contexts/AuthProvider/useAuth';
import { RECEBIMENTO } from '../../../../routes_api';
import { toast } from 'react-toastify';

export const Recebimento = React.forwardRef((props: any, ref) => {
    const auth = useAuth();

    const refDialog = useRef<any>(null);
    const itemEls = useRef<{ [key: string]: any }>({});
    const [register, setRegister] = useState<any>();

    function setLoading(value: boolean) {
        refDialog.current.setLoading(value);

        if (itemEls)
            Object.values(itemEls.current).forEach((elementRef: any) => { elementRef.setLoading(value) });
    }

    function openModal() {
        refDialog.current.openModal();

    }

    function setValues(_register: any) {
        setRegister(_register)
    }

    useImperativeHandle(ref, () => ({
        openModal: openModal,
        setValues: setValues
    }));

    function receber() {

        async function recebe() {
            setLoading(true);

            let request: any = {
                contador: itemEls.current['contador'].getValue(),
                valor: itemEls.current['valorReceber'].getValue()
            };

            try {
                const response: any = await auth.requestPost(RECEBIMENTO, request);
                toast.success(response.data.mensagem);

                if (props.sucess)
                    props.sucess();

                refDialog.current.closeModal();
            } catch (error: any) {
                toast.error(error?.response?.data?.mensagem ? error?.response?.data?.mensagem : 'Erro ao concluir a operação.');
                setLoading(true);
            }
        }

        if (itemEls.current['valorReceber'].getValue() <= register.valorAberto) {
            recebe();
        }
    }

    function changeValorReceber(_register: any) {
        var ref = itemEls.current['novoValorAberto'];
        ref.setCustomValue(register.valorAberto - _register);
    }

    const inputs = [
        { id: 'contador', type: 'text', label: 'Contador', disabled: true, xs: 3 },
        { id: 'cliente', type: 'text', label: 'Cliente', disabled: true, xs: 9 },
        { id: 'numDocumento', type: 'text', label: 'Número Doc.', disabled: true, xs: 4 },
        { id: 'emissao', label: 'Emissão', type: 'datetime-local', disabled: true, xs: 4 },
        { id: 'vencimento', label: 'Vencimento', type: 'datetime-local', disabled: true, xs: 4 },
        { id: 'valor', label: 'Valor Aberto', type: 'number', disabled: true, xs: 4 },
        { id: 'valorReceber', label: 'Valor Recebimento', type: 'number', required: true, isGreaterThanZero: true, xs: 4, onAfterChange: changeValorReceber, maxValue: register?.valorAberto },
        { id: 'novoValorAberto', label: 'Restante', type: 'number', disabled: true, xs: 4 }
    ]

    return (
        <BsDialog
            ref={refDialog}
            title={'Recebimento'}
            cancelClick={() => refDialog.current.closeModal()}
            confirmClick={() => receber()}
            labelConfirm={'Receber'}
            content={
                register &&
                <Grid container spacing={2} sx={{ mt: 0.5 }} >
                    {
                        inputs.map((input: any, i: number) => (
                            <Grid key={i} item xs={MobVersion() ? 12 : input.xs}>
                                <Inputs
                                    ref={(e: any) => itemEls.current[input.id] = e}
                                    inp={input}
                                    submitted={true}
                                    register={{
                                        ...register,
                                        cliente: register.cliente.nome,
                                        valorReceber: register.valorAberto,
                                        novoValorAberto: 0
                                    }}
                                    onAfterChange={(_register: any) => {
                                        input.onAfterChange(_register)
                                    }}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
            }
        />)
})