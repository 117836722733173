import { Route, Routes } from "react-router-dom"
import { RequireAuth, RequireAuthAdm, RequireAuthNormal, RequireAuthRevenda, RequireAuthSubRevenda } from "../contexts/AuthProvider/RequireAuth"
import { Cliente } from "../pages/Cadastros/Cliente"
import { Dashboard } from "../pages/Dashboard"
import { Login } from "../pages/Login"
import { Servico } from "../pages/Cadastros/Servico"
import { TipoServico } from "../pages/Cadastros/TipoServico"
import { LogAtividade } from "../pages/Historico/LogAtividades"
import { Disparo } from "../pages/WhatsApp/Disparo"
import { TemplateMensagem } from "../pages/WhatsApp/TemplateMensagem"
import { Revendas } from "../pages/Administrativo/Revendas"
import { Master } from "../pages/Revenda/Master"
import { Plano } from "../pages/Revenda/Plano"
import { Renovacoes } from "../pages/Historico/Renovacoes"
import { MinhaContaPrincipal } from "../pages/MinhaConta"
import { ListaTransmissao } from "../pages/Publicidade/ListaTransmissao"
import { ContatoAvulso } from "../pages/Publicidade/ContatoAvulso"
import { SolicitarCredito } from "../pages/Revenda/CreditoSolicitado"
import { SubRevenda } from "../pages/Sub-revenda"
import { GestaoServico } from "../pages/Cadastros/GestaoServico"
import { ContasReceber } from "../pages/Financeiro/ContasReceber"
import { MovimentoFinanceiro } from "../pages/Financeiro/MovimentoFinanceiro"
import { Instancia } from "../pages/WhatsApp/Instancia"
import { Assistente } from "../pages/AssistenteVirtual/Assistente"
import { AtendimentoIA } from "../pages/AssistenteVirtual/AtendimentoIA"

export const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<RequireAuthNormal><Dashboard /></RequireAuthNormal>} />

            {/* Cadastros */}
            <Route path="/clientes" element={<RequireAuthNormal><Cliente /></RequireAuthNormal>} />
            <Route path="/gestaoservicos" element={<RequireAuthNormal><GestaoServico /></RequireAuthNormal>} />
            <Route path="/servicos" element={<RequireAuthNormal><Servico /></RequireAuthNormal>} />
            <Route path="/tiposservicos" element={<RequireAuthNormal><TipoServico /></RequireAuthNormal>} />

            {/* Whatsapp */}
            <Route path="/whatsapp/instancias" element={<RequireAuth><Instancia /></RequireAuth>} />
            <Route path="/whatsapp/disparos" element={<RequireAuth><Disparo /></RequireAuth>} />
            <Route path="/whatsapp/templatesmensagem" element={<RequireAuth><TemplateMensagem /></RequireAuth>} />

            {/* Assistente Virtual */}
            <Route path="/assist_virtual/assistente" element={<RequireAuth><Assistente /></RequireAuth>} />
            <Route path="/assist_virtual/atendimento" element={<RequireAuth><AtendimentoIA /></RequireAuth>} />

            {/* Financeiro */}
            <Route path="/contasreceber" element={<RequireAuthNormal><ContasReceber /></RequireAuthNormal>} />
            <Route path="/movimentofinanceiro" element={<RequireAuthNormal><MovimentoFinanceiro /></RequireAuthNormal>} />

            {/* Publicidade */}
            <Route path="/publicidade/contatosavulsos" element={<RequireAuth><ContatoAvulso /></RequireAuth>} />
            <Route path="/publicidade/listatransmissao" element={<RequireAuth><ListaTransmissao /></RequireAuth>} />

            {/* Histórico */}
            <Route path="/masterlogs" element={<RequireAuthNormal><LogAtividade /></RequireAuthNormal>} />
            <Route path="/renovacoes" element={<RequireAuthNormal><Renovacoes /></RequireAuthNormal>} />

            {/* Revenda */}
            <Route path="/revendas/masters" element={<RequireAuthRevenda><Master /></RequireAuthRevenda>} />
            <Route path="/revendas/planos" element={<RequireAuthRevenda><Plano /></RequireAuthRevenda>} />
            <Route path='/revendas/creditosolicitado' element={<RequireAuthRevenda><SolicitarCredito /></RequireAuthRevenda>} />

            {/* Sub-revenda */}
            <Route path='/sub-revenda' element={<RequireAuthSubRevenda><SubRevenda /></RequireAuthSubRevenda>} />

            {/*Administrativo */}
            <Route path="/administrativo/revenda" element={<RequireAuthAdm><Revendas /></RequireAuthAdm>} />

            {/* Minha Conta */}
            <Route path="/meusdados" element={<RequireAuth><MinhaContaPrincipal /></RequireAuth>} />
        </Routes>
    )
}