import React, { useImperativeHandle, useState } from "react";
import { Grid, useTheme } from '@mui/material';
import { BsCard } from "../../BsCard";
import { MobVersion } from "../../../utils";

const ContainerChart = (props: any, ref: any) => {
    const [filtered, setFiltered] = useState(false);
    const theme = useTheme();

    useImperativeHandle(ref, () => ({
        setFiltered
    }))

    return (
        <Grid
            item
            xs={(filtered || MobVersion()) ? 12 : props.xs ? props.xs : 6}
        >
            <BsCard icon={props.icon} title={props.title} subtitle={props.subtitle} closeVisible={filtered} onClose={props.onClose} style={{ backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff' }}>
                {props.children}
                {props.detail}
            </BsCard>
        </Grid>

    );
}

export default React.forwardRef(ContainerChart);