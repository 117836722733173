import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Form, InputGroup, Col } from "react-bootstrap";
import { NumericFormat } from 'react-number-format';
import { FormFeedback, FormLabel, GroupText } from "../Controls";

interface MonetaryInputProps {
    ref?: any;
    id: string;
    label?: string;
    feedback?: string;
    required?: boolean;
    md?: string;
    value?: any;
    disabled?: any;
    simbolstart?: any;
    size?: any;
    onAfterChange?: any;
}

export const MonetaryInput: React.FC<MonetaryInputProps> = forwardRef((props: MonetaryInputProps, ref: any) => {
    const [value, setValue] = useState<any>(props.value ?? 0);
    const [error, setError] = useState<boolean>(false);

    function getValue() {
        return Number(Number(String(value).replace('R$ ', '').replace(',', '.')).toFixed(2));
    }

    useImperativeHandle(ref, () => ({
        getValue,
        setValue,
        setError
    }));

    function onChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (error)
            setError(false);

        if (props.onAfterChange)
            props.onAfterChange(String(e.target.value).replace('R$ ', '').replace(',', '.'));

        setValue(e.target.value);
    }

    return (
        <Form.Group as={Col} md={props?.md || "12"} controlId={props.id}>
            {props.label && <FormLabel>{(props.required ? '*' : '') + props.label}</FormLabel>}
            <InputGroup size={props.size ? props.size : "sm"} hasValidation>
                {props.simbolstart && <GroupText>{props.simbolstart}</GroupText>}
                <NumericFormat
                    value={value}
                    customInput={Form.Control}
                    decimalSeparator={','}
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="R$ "
                    spellCheck={false}
                    required={props.required}
                    isInvalid={error}
                    disabled={props.disabled}
                    style={{ textAlign: 'right', fontSize: '14px' }}
                    onChange={onChange}
                    className={error ? 'is-invalid' : ''}
                />
                <FormFeedback type="invalid">
                    {error && (props.feedback || (props.required ? 'Campo obrigatório.' : ''))}
                </FormFeedback>
            </InputGroup>
        </Form.Group>
    );
});
