import { BsColumns, BsTelaPadrao } from "../../../components/BsTelaPadrao";
import { MODEL_INSTANCIA } from "../../../models";
import { intToSessaoWhatsApp, labelSessaoWhatsApp } from "../../../utils";
import { useAuth } from '../../../contexts/AuthProvider/useAuth';
import { DESCONECTAR_INSTANCIA } from '../../../routes_api';
import { useRef } from 'react';
import { QrCode } from './QrCode';
import { BsConfirmation } from '../../../components/BsConfirmation';
import { MensagemTeste } from './MensagemTeste';
import { toast } from 'react-toastify';
import { IoQrCode } from "react-icons/io5";
import { IoMdPower } from "react-icons/io";
import { LuMessagesSquare } from "react-icons/lu";

export const Instancia = () => {
    const auth = useAuth();

    const dataTableRef = useRef<any>();
    const qrCodeRef = useRef<any>();
    const confirmationRef = useRef<any>();
    const msgTesteRef = useRef<any>();

    function onAfterConnect() {
        dataTableRef.current.refresh();
    }

    function conectarInstancia(rowData: any) {
        if (rowData.situacao === 1)
            toast.error('Instância já está conectada.');
        else
            qrCodeRef.current.openModal(rowData.id);
    }

    async function desconectar(_id: any) {
        try {
            const response: any = await auth.requestPost(DESCONECTAR_INSTANCIA, { id: _id });

            toast.success(response.data.mensagem);
            dataTableRef.current.refresh();
        } catch (error: any) {
            toast.error(error?.response?.data?.mensagem ? error?.response?.data?.mensagem : 'Erro ao concluir a operação.');;
        };
    }

    function desconectarInstancia(rowData: any) {
        if (rowData.situacao === 0)
            toast.error('Instância já está desconectada.')
        else
            confirmationRef.current.openModal(rowData.id);
    }

    function enviarMsgTeste(rowData: any) {
        msgTesteRef.current.openModal(rowData.id);
    }

    const functionsGrid: Array<any> = [
        { icon: <IoQrCode />, color: '#14B8A6', onClick: conectarInstancia, tooltip: 'Conectar Instância' },
        { icon: <IoMdPower />, color: '#EF4444', onClick: desconectarInstancia, tooltip: 'Desconectar Instância' },
        { icon: <LuMessagesSquare />, color: '#3B82F6', onClick: enviarMsgTeste, tooltip: 'Enviar Mensagem de Teste' }
    ]

    const cols: Array<BsColumns> = [
        { field: 'id', header: 'ID', sortable: true },
        { field: 'descricao', header: 'Descrição', sortable: true },
        { field: 'situacao', header: 'Situação', body: (e: any) => labelSessaoWhatsApp(e.situacao), style: { width: '100px' } },
        { field: 'edit', exportable: false, adicionalFunctions: functionsGrid, style: { minWidth: '4rem', width: '4rem' } }
    ];

    const onFilter = (value: any, filter: string) => {
        return String(value.id).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.descricao).toLowerCase().includes(filter.toLowerCase()) ||
            String(intToSessaoWhatsApp(value.situacao)).toLowerCase().includes(filter.toLowerCase());
    }

    return (
        <>
            <BsTelaPadrao
                ref={dataTableRef}
                sortcolumn={'descricao'}
                sortcolumnorder={1}
                columns={cols}
                model={MODEL_INSTANCIA}
                onFilterRegisters={onFilter}
                disabledEdit={true}
            />
            <QrCode
                ref={qrCodeRef}
                afterConnect={onAfterConnect}
            />
            <BsConfirmation
                ref={confirmationRef}
                confirmClick={(_id: any) => desconectar(_id)}
                message={'Deseja mesmo se desconectar da sessão?'}
                confirmAlert={true}
            />
            <MensagemTeste
                ref={msgTesteRef}
            />
        </>
    );
}
