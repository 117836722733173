import { Grid } from "@mui/material"

import React, { useContext, useImperativeHandle, useRef, useState } from "react";
import { toast } from 'react-toastify';
import { AuthContext } from "../../../contexts/AuthProvider";
import { BsDialog } from "../../../components/BsDialog";
import BsFileUploader from "../../../components/BsFileUploader";
import { Inputs } from "../../../components/Inputs";
import { MODEL_TEMPLATE_MENSAGEM } from "../../../models";

interface Props {
    onAfterFinish?: any;
}

export const Notificar = React.forwardRef((props: Props, ref) => {
    const auth = useContext(AuthContext);

    const [dados, setDados] = useState<any>();

    const dialogRef = useRef<any>();
    const templateRef = useRef<any>();
    const notificacaoRef = useRef<any>();
    const fileRef = useRef<any>();

    const [submitted, setSubmitted] = useState(false);

    function setLoading(value: boolean) {
        dialogRef.current.setLoading(value);
        notificacaoRef.current.setLoading(value);
        templateRef.current.setLoading(value);
    }

    async function openModal(contador: number, apiEnvio: string, nome?: string, moduleName?: string, typeMessage?: number) {
        await setDados({ contador, apiEnvio, nome, moduleName, typeMessage });

        setSubmitted(false);
        dialogRef.current.openModal();
    }

    useImperativeHandle(ref, () => ({
        openModal
    }));

    function change(_register: any) {
        notificacaoRef.current.setCustomValue(_register.texto);
        fileRef.current.disableControl(!_register.idArquivo);
    }

    const notificar = () => {
        setSubmitted(true);

        var doc: any = fileRef.current.getFileInfo();
        var notificacao: any = notificacaoRef.current.getValue();
        var template: any = templateRef.current.getValue();

        async function notifica() {
            setLoading(true);

            try {
                let request: any = {};
                request.contador = dados.contador;
                request.texto = notificacao;

                if (template && template.idArquivo)
                    request.idArquivo = template.idArquivo;

                if (doc) {
                    request.arquivo = {
                        tipo: doc.type === 'image' ? 1 : doc.type === 'video' ? 3 : 4,
                        formato: doc.extension,
                        descricao: doc.name,
                        base64: doc.base64
                    }
                }

                const response: any = await auth.requestPost(dados.apiEnvio, request);

                toast.success(response.data.mensagem);

                if (props.onAfterFinish)
                    props.onAfterFinish(dados.contador);

                dialogRef.current.closeModal();
            } catch (error: any) {
                toast.error(error?.response?.data?.mensagem ? error?.response?.data?.mensagem : 'Erro ao concluir a operação.');
            }

            setLoading(false);
        }

        if (notificacao)
            notifica();
    }

    return (
        <BsDialog
            ref={dialogRef}
            maxWidth={'sm'}
            title={'Notificar ' + dados?.moduleName}
            cancelClick={() => dialogRef.current.closeModal()}
            confirmClick={notificar}
            content={
                dados &&
                <Grid container spacing={2} sx={{ mt: 0.5 }} >
                    <Grid item xs={12}>
                        <Inputs
                            inp={{ id: 'modulo', label: dados.moduleName, type: 'text', multiline: true, maxRows: 4, disabled: true }}
                            register={{ modulo: dados.nome }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Inputs
                            ref={templateRef}
                            inp={{
                                id: 'template', label: 'Template Mensagem', type: 'select', model: MODEL_TEMPLATE_MENSAGEM, disableEdit: true, onAfterChange: (_register: any) => change(_register),
                                filterModel: { field: 'tipo', value: dados.typeMessage, select: true }
                            }}
                            submitted={submitted}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Inputs
                            ref={notificacaoRef}
                            inp={{ id: 'notificacao', label: 'Notificação', type: 'text', multiline: true, maxLength: 1500, maxRows: 8, required: true }}
                            submitted={submitted}
                        />
                        <BsFileUploader ref={fileRef} style={{ marginTop: '15px' }} />
                    </Grid>

                </Grid>
            }
        />
    )
});