import { toast } from "react-toastify";
import { BsLabelDataTable } from "../components/BsLabelDataTable";
import { colorError } from "../styles/theme";
import * as CryptoJS from 'crypto-js';

const COLOR_BLUE = '#3b82f6';
const COLOR_YELLOW = '#f59e0b';
const COLOR_RED = '#ff4444';
const COLOR_ORANGE = '#ea6c27';
const COLOR_GREEN = '#22c55e';
const COLOR_PURPLE = '#633fa1';

// Formação de Dados
export const formatedDate = (date: number) => {
    if (String(date) === '')
        return '';

    var newDate = new Date(date);
    return String("0" + newDate.getDate()).slice(-2) + '/' + String("0" + (newDate.getMonth() + 1)).slice(-2) + '/' + newDate.getFullYear();
}

export const formatedDateTime = (date: number) => {
    if (String(date) === '')
        return '';

    var newDate = new Date(date);
    return String("0" + newDate.getDate()).slice(-2) + '/' + String("0" + (newDate.getMonth() + 1)).slice(-2) + '/' + newDate.getFullYear() + ' ' + String("0" + (newDate.getHours())).slice(-2) + ':' + String("0" + (newDate.getMinutes())).slice(-2);
}

export const formatedDateTimeSeconds = (date: number) => {
    if (String(date) === '')
        return '';

    var newDate = new Date(date);
    return String("0" + newDate.getDate()).slice(-2) + '/' + String("0" + (newDate.getMonth() + 1)).slice(-2) + '/' + newDate.getFullYear() + ' ' + String("0" + (newDate.getHours())).slice(-2) + ':' + String("0" + (newDate.getMinutes())).slice(-2) + ':' + String("0" + (newDate.getSeconds())).slice(-2);
}

export const formatedTelefone = (telefone: string) => {
    if (!telefone)
        return '';

    if (telefone.trim().replace('+', '').length < 12)
        return telefone;

    var formatNumber: any;

    if ((telefone.length === 12) || (telefone.length === 13)) {
        formatNumber = telefone.length === 12 ? telefone.match(/(\d{2})(\d{2})(\d{4})(\d{4})/) : telefone.match(/(\d{2})(\d{2})(\d{5})(\d{4})/);
        return "+" + formatNumber[1] + " (" + formatNumber[2] + ") " + formatNumber[3] + "-" + formatNumber[4];
    }
    else
        return telefone;
}

export const formatedPrice = (valor: Number) => {
    if (String(valor) === '')
        return '';

    return valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
}

// Datas Formatadas

export const getDataHoje = () => {
    var hoje = formatedDate(Number(new Date())).split("/")
    return new Date(Number(hoje[2]), Number(hoje[1]) - 1, Number(hoje[0]));
}

export const getDataAmanha = () => {
    var hoje = formatedDate(Number(new Date())).split("/")
    var amanha = new Date(Number(hoje[2]), Number(hoje[1]) - 1, Number(hoje[0]));
    return amanha.setDate(amanha.getDate() + 1);
}

// Conversão da Situação Ativo/Inativo

export const intToAtivoInativo = (value: number) => {
    return value === 1 ? 'Ativo' : 'Inativo';
}

export const intToAtivoInativoColor = (value: number) => {
    return value === 1 ? COLOR_BLUE : COLOR_YELLOW;
}

export const labelAtivoInativo = (value: number) => {
    return <BsLabelDataTable color={intToAtivoInativoColor(value)}>{intToAtivoInativo(value)}</BsLabelDataTable>
}

// Converfsão de Sim ou Não

export const intToSimNao = (value: any) => {
    return value !== null ? 'Sim' : 'Não';
}

export const intToSimNaoColor = (value: any) => {
    return value !== null ? COLOR_BLUE : COLOR_ORANGE;
}

export const labelSimNao = (value: number) => {
    return <BsLabelDataTable color={intToSimNaoColor(value)}>{intToSimNao(value)}</BsLabelDataTable>
}

// Conversão Situação Contas Receber

export function intToSituacaoCr(value: number) {
    return value === 0 ? 'Aberto' : value === 1 ? 'Pago' : 'Cancelado';
}

export const labelSituacaoCr = (value: number) => {
    return <BsLabelDataTable color={intToSituacaoDisparoColor(value)}>{intToSituacaoCr(value)}</BsLabelDataTable>
}

// Conversão da Situação Disparo

export const intToSituacaoDisparo = (value: number) => {
    return value === 0 ? 'Aguardando' : value === 1 ? 'Realizado' : 'Cancelado';
}

export const intToSituacaoDisparoColor = (value: number) => {
    return value === 0 ? COLOR_YELLOW : value === 1 ? COLOR_BLUE : COLOR_RED;
}

export const labelSituacaoDisparo = (value: number) => {
    return <BsLabelDataTable color={intToSituacaoDisparoColor(value)}>{intToSituacaoDisparo(value)}</BsLabelDataTable>
}

// Conversão da Datas Expiração

export const getDataExpiracao = (_expiracao: number) => {
    var hoje = getDataHoje();
    var amanha = getDataAmanha();
    var expiracao = formatedDate(_expiracao).split("/");
    var expiracaoFormatada = new Date(Number(expiracao[2]), Number(expiracao[1]) - 1, Number(expiracao[0]));

    return (Number(hoje) > Number(expiracaoFormatada)) ? 'Expirado' : (Number(hoje) === Number(expiracaoFormatada)) ? 'Expira Hoje' :
        (Number(amanha) === Number(expiracaoFormatada)) ? 'Expira Amanhã' : 'Em dia';
}

export const getDataExpiracaoColor = (_expiracao: number) => {
    var hoje = getDataHoje();
    var amanha = getDataAmanha();
    var expiracao = formatedDate(_expiracao).split("/");
    var expiracaoFormatada = new Date(Number(expiracao[2]), Number(expiracao[1]) - 1, Number(expiracao[0]));

    return (Number(hoje) > Number(expiracaoFormatada)) ? COLOR_RED : (Number(hoje) === Number(expiracaoFormatada)) ? COLOR_ORANGE :
        (Number(amanha) === Number(expiracaoFormatada)) ? COLOR_YELLOW : COLOR_GREEN;
}

export const labelExpiracao = (_expiracao: number) => {
    return <BsLabelDataTable color={getDataExpiracaoColor(_expiracao)}>{getDataExpiracao(_expiracao)}</BsLabelDataTable>
}

// Conversão Situação Sessão WhatsApp 

export const intToSessaoWhatsApp = (value: number) => {
    return value === 1 ? 'Conectado' : value === 0 ? 'Desconectado' : 'Verificando...';
}

export const intToSessaoWhatsAppColor = (value: number) => {
    return value === 1 ? COLOR_BLUE : COLOR_YELLOW;
}

export const labelSessaoWhatsApp = (value: number) => {
    return <BsLabelDataTable color={intToSessaoWhatsAppColor(value)}>{intToSessaoWhatsApp(value)}</BsLabelDataTable>
}

// Conversão de Origem Disparo

export const intToOrigemDisparo = (value: number) => {
    return value === 0 ? 'Automático' : value === 1 ? 'Manual' : 'Integração';
}

export const intToOrigemDisparoColor = (value: number) => {
    return value === 0 ? COLOR_BLUE : value === 1 ? COLOR_GREEN : COLOR_PURPLE;
}

export const labelOrigemDisparo = (value: number) => {
    return <BsLabelDataTable color={intToOrigemDisparoColor(value)}>{intToOrigemDisparo(value)}</BsLabelDataTable>
}

// Conversão Lista de Tranmissão

export const intToConsiderarAtivos = (value: number) => {
    return value === 0 ? 'Inativo' : value === 1 ? 'Ativo' : 'Todos';
}

export const intToConsiderarVencidos = (value: number) => {
    return value === 0 ? 'Vencido' : value === 1 ? 'Em dia' : 'Todos';
}

export const intToTipoLista = (value: number) => {
    return value === 0 ? 'Privada' : 'Pública';
}

// Conversão Tipo Movimentos Financeiros

export const intToTipoMovFinanceiro = (value: number) => {
    return value === 0 ? 'Entrada' : 'Saída';
}

export const intToTipoMovFinanceiroColor = (value: number) => {
    return value === 0 ? COLOR_BLUE : COLOR_PURPLE;
}

export const labelTipoMovimento = (value: number) => {
    return <BsLabelDataTable color={intToTipoMovFinanceiroColor(value)}>{intToTipoMovFinanceiro(value)}</BsLabelDataTable>
}

// Conversão Situação Crédito Solicitado

export const intToCreditoSolicitado = (value: number) => {
    return value === 0 ? 'Aguardando Pagamento' : value === 1 ? 'Pago' : 'Cancelado';
}

export const intToCreditoSolicitadoColor = (value: number) => {
    return value === 0 ? COLOR_YELLOW : value === 1 ? COLOR_BLUE : colorError;
}

export const labelCreditoSolicitado = (value: number) => {
    return <BsLabelDataTable color={intToCreditoSolicitadoColor(value)}>{intToCreditoSolicitado(value)}</BsLabelDataTable>
}

export const intToTipoCredito = (value: number) => {
    return value === 0 ? 'Próprio' : 'Revenda';
}

export const intToTipoCreditoColor = (value: number) => {
    return value === 0 ? COLOR_GREEN : COLOR_PURPLE;
}

export const labelTipoCredito = (value: number) => {
    return <BsLabelDataTable color={intToTipoCreditoColor(value)}>{intToTipoCredito(value)}</BsLabelDataTable>
}

// Conversão Tipo Treino IA

export function intToTipoTreino(value: any) {
    return value === 0 ? 'Texto' : 'URL/Link';
}

// Conversão Tipo Assistente

export function intToTipoAssistente(value: any) {
    return value === 1.0 ? 'Mais Preciso' : value === 0.5 ? 'Mais Balanceado' : 'Mais Criativo';
}

// Conversão Situação Contas Receber

export function intToSituacaoAtendimento(value: number) {
    return value === 0 ? 'Aberto' : 'Concluído';
}

export const intToSituacaoAtendimentoColor = (value: number) => {
    return value === 0 ? COLOR_YELLOW : COLOR_GREEN;
}

export const labelSituacaoAtendimento = (value: number) => {
    return <BsLabelDataTable color={intToSituacaoAtendimentoColor(value)}>{intToSituacaoAtendimento(value)}</BsLabelDataTable>
}

//

export function MobVersion() {
    return window.innerWidth <= 800;
}

export const selectYesOrNo = (
    [0, 1].map((item) => (
        { contador: item, descricao: (item === 0 ? 'Não' : 'Sim') }
    ))
)

export const findIndexById = (list: any, contador: number) => {
    let index = -1;
    for (let i = 0; i < list.length; i++) {
        if (list[i].contador === contador) {
            index = i;
            break;
        }
    }
    return index;
}

export function getApproximatedDate(date: number) {
    const currentDate = new Date();
    const targetDate = new Date(date);

    const timeDiff = currentDate.getTime() - targetDate.getTime();
    const secondsDiff = Math.floor(timeDiff / 1000);
    const minutesDiff = Math.floor(secondsDiff / 60);
    const hoursDiff = Math.floor(minutesDiff / 60);
    const daysDiff = Math.floor(hoursDiff / 24);
    const weeksDiff = Math.floor(daysDiff / 7);
    const monthsDiff = Math.floor(daysDiff / 30);
    const yearsDiff = Math.floor(monthsDiff / 12);

    if (secondsDiff < 60) {
        return 'há ± alguns segundos';
    } else if (minutesDiff < 60) {
        return `há ± ${minutesDiff} minuto${minutesDiff === 1 ? '' : 's'}`;
    } else if (hoursDiff < 24) {
        return `há ± ${hoursDiff} hora${hoursDiff === 1 ? '' : 's'}`;
    } else if (daysDiff < 7) {
        return `há ± ${daysDiff} dia${daysDiff === 1 ? '' : 's'}`;
    } else if ((monthsDiff === 0) && (weeksDiff < 6)) {
        return `há ± ${weeksDiff} semana${weeksDiff === 1 ? '' : 's'}`;
    } else if (monthsDiff < 12) {
        return `há ± ${monthsDiff} mês${monthsDiff === 1 ? '' : 'es'}`;
    } else {
        return `há ± ${yearsDiff} ano${yearsDiff === 1 ? '' : 's'}`;
    }
};

export function encrypt(term: string): string {
    const intToBin = (num: number, pad: number) => {
        let binary = num.toString(2);
        return binary.padStart(pad, '0');
    };

    const binToInt = (binary: string): number => {
        return parseInt(binary, 2);
    };

    const b64 = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";

    let buffer = '';
    let returnVal = '';

    for (let i = 0; i < term.length; i++) {
        buffer += intToBin(term.charCodeAt(i), 8);
    }

    buffer += '0'.repeat(Math.ceil(buffer.length / 6) * 6 - buffer.length);

    for (let i = 0; i < buffer.length; i += 6) {
        const chunk = buffer.slice(i, i + 6);
        returnVal += b64[binToInt(chunk)];
    }

    const padding = '_'.repeat(Math.ceil(returnVal.length / 4) * 4 - returnVal.length);

    return returnVal + padding;
}


export function decrypt(term: string): string {
    const intToBin = (num: number, pad: number) => {
        let binary = num.toString(2);
        return binary.padStart(pad, '0');
    };

    const binToInt = (binary: string): number => {
        return parseInt(binary, 2);
    };

    const b64 = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";

    // Remove underscores from the input string
    term = term.replace(/_/g, '');

    let buffer = '';
    let returnVal = '';

    for (let i = 0; i < term.length; i++) {
        const charIndex = b64.indexOf(term[i]);
        buffer += intToBin(charIndex, 6);
    }

    for (let i = 0; i < buffer.length; i += 8) {
        const chunk = buffer.slice(i, i + 8);
        returnVal += String.fromCharCode(binToInt(chunk));
    }

    return returnVal;
}

export function formatDataCompare(date: any) {
    if (!date)
        return ''

    const d = new Date(date);
    return d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2);
}

export async function newDateExpiracao(_dateServer: any, _oldDataExpiracao: any, _dataRenovacao: any, _meses: number) {
    let dateServer = new Date(Date.parse(_dateServer));
    let dataExpiracao = new Date(Date.parse(String(_oldDataExpiracao)));

    let maiorData = _dataRenovacao && Number(dateServer) >= Number(dataExpiracao) ? dateServer : dataExpiracao;
    maiorData.setMonth(Number(maiorData.getMonth()) + Number(_meses));

    let newDate = new Date(Date.UTC(
        maiorData.getFullYear(),
        maiorData.getMonth(),
        maiorData.getDate(),
        maiorData.getHours(),
        maiorData.getMinutes(),
        maiorData.getSeconds()
    ));

    return newDate.toISOString().slice(0, 16);
}


export function showError(error: any) {
    let _message = error?.response?.data?.mensagem || 'Erro ao concluir a operação.';
    console.log(_message);
    toast.error(_message, _message);
}

export function cpfIsValid(cpf: any) {
    var soma = 0;
    var resto;

    var cpfStr = cpf.replace(/[^\d]/g, '')

    if (cpfStr.length !== 11)
        return false;

    if ([
        '00000000000',
        '11111111111',
        '22222222222',
        '33333333333',
        '44444444444',
        '55555555555',
        '66666666666',
        '77777777777',
        '88888888888',
        '99999999999',
    ].indexOf(cpfStr) !== -1)
        return false;

    for (let i = 1; i <= 9; i++)
        soma = soma + parseInt(cpfStr.substring(i - 1, i)) * (11 - i);

    resto = (soma * 10) % 11

    if ((resto === 10) || (resto === 11))
        resto = 0

    if (resto !== parseInt(cpfStr.substring(9, 10)))
        return false

    soma = 0

    for (let i = 1; i <= 10; i++)
        soma = soma + parseInt(cpfStr.substring(i - 1, i)) * (12 - i)

    resto = (soma * 10) % 11

    if ((resto === 10) || (resto === 11))
        resto = 0

    if (resto !== parseInt(cpfStr.substring(10, 11)))
        return false

    return true
}

export function emailIsValid(email: any) {
    var user = email.substring(0, email.indexOf("@"));
    var dominio = email.substring(email.indexOf("@") + 1, email.length);

    if ((user.length >= 1) &&
        (dominio.length >= 3) &&
        (user.search("@") === -1) &&
        (dominio.search("@") === -1) &&
        (user.search(" ") === -1) &&
        (dominio.search(" ") === -1) &&
        (dominio.search(".") !== -1) &&
        (dominio.indexOf(".") >= 1) &&
        (dominio.lastIndexOf(".") < dominio.length - 1))
        return true
    else
        return false
}

export function convertToMd5(str: any) {
    return CryptoJS.MD5(str).toString();
}