// ----- Rotas da API ----- 

// DASHBOARD
export const CLIENTES_ATIVOS: string = 'dashboard/cliente';
export const NOVOS_CLIENTES: string = 'dashboard/novosClientes';
export const DISPAROS_REALIZADOS: string = 'dashboard/disparosRealizados';
export const SERVICOS_UTILIZADOS: string = 'dashboard/servicosUsados';
export const TIPOSSERVICOS_UTILIZADOS: string = 'dashboard/tiposServicoUsados';
export const RESULTADO_BRUTO: string = 'dashboard/resultadoBruto';

// CADASTROS

// -- Tipo Serviço
export const CONSULTA_TIPOSERVICO: string = 'tiposServico';
export const SALVAR_TIPOSERVICO: string = 'tipoServico/salvar';
export const EXCLUIR_TIPOSERVICO: string = 'tipoServico/excluir';

// -- Serviço
export const CONSULTA_SERVICO: string = 'servicos';
export const CONSULTA_SERVICO_COMPLETO: string = 'servicosCompleto';
export const SALVAR_SERVICO: string = 'servico/salvar';
export const EXCLUIR_SERVICO: string = 'servico/excluir';

// -- Cliente
export const CONSULTA_CLIENTE: string = 'clientes';
export const SALVAR_CLIENTE: string = 'cliente/salvar';
export const NOTIFICAR_CLIENTE: string = 'cliente/notificar';
export const RENOVAR_CLIENTE: string = 'cliente/renovar';
export const EXISTE_WHATSAPP: string = 'clientes/whatsapp';
export const EXCLUIR_CLIENTE: string = 'cliente/excluir';

// -- ClienteServiço
export const CONSULTA_CLIENTESERVICO: string = 'clientesServicos';
export const SALVAR_CLIENTESERVICO: string = 'cliente/servico/salvar';
export const EXCLUIR_CLIENTESERVICO: string = 'cliente/servico/excluir';

// WHATSAPP

// -- INSTANCIA
export const CONSULTA_INSTANCIA: string = 'instancias';
export const SALVAR_INSTANCIA: string = 'instancia/salvar';
export const CONECTAR_INSTANCIA: string = 'instancia/conectar';
export const DESCONECTAR_INSTANCIA: string = 'instancia/desconectar';
export const INFO_INSTANCIA: string = 'instancia/info';
export const ENVIAR_MSG_TESTE: string = 'instancia/mensagemTeste';

// -- Minha Sessão
export const MINHA_SESSAO: string = '';

// -- Mensagem Padrão
export const CONSULTA_TIPOS_TEMPLATE: string = 'templateMensagem/tipos';
export const CONSULTA_TEMPLATEMENSAGEM: string = 'templatesMensagem';
export const SALVAR_TEMPLATEMENSAGEM: string = 'templateMensagem/salvar';
export const EXCLUIR_TEMPLATEMENSAGEM: string = 'templateMensagem/excluir';
export const CONSULTA_VARIAVEIS: string = 'templateMensagem/variaveis';
export const ANEXAR_IMAGEM_TEMPLATE: string = 'templateMensagem/salvarArquivo';
export const DELETAR_IMAGEM_TEMPLATE: string = 'templateMensagem/excluirArquivo';

// -- Disparos
export const CONSULTA_DISPAROS: string = 'disparos';
export const CANCELAR_DISPAROS: string = 'disparo/cancelar';
export const CANCELAR_TODOS_PENDENTES: string = 'disparo/cancelarPendentes';

// PUBLICIDADE

// -- Contatos Avulsos
export const CONSULTA_CONTATO_AVULSO: string = 'contatosAvulso';
export const SALVAR_CONTATO_AVULSO: string = 'contatoAvulso/salvar';
export const EXCLUIR_CONTATO_AVULSO: string = 'contatoAvulso/excluir'
export const UPLOAD_CSV: string = 'contatoAvulso/uploadExcel';
export const DOWNLOAD_MODELO: string = 'contatoAvulso/modeloExcel';

// -- Listas de Transmissões
export const CONSULTA_LISTA_TRANSMISSAO: string = 'listasTransmissao';
export const SALVAR_LISTA_TRANSMISSAO: string = 'listaTransmissao/salvar';
export const EXCLUIR_LISTA_TRANSMISSAO: string = 'listaTransmissao/excluir';
export const NOTIFICAR_LISTA_TRANSMISSAO: string = 'listaTransmissao/notificar';

// HISTÓRICO

// -- Renovações
export const CONSULTA_RENOVACOES: string = 'clienteRenovacoes';

// -- Log Atividades
export const CONSULTA_LOG_ATIVIDADES: string = 'masterLogs';

// REVENDAS

// -- Master
export const CONSULTA_MASTER: string = 'master';
export const CONSULTA_REVENDA_MASTER: string = 'revenda/masters';
export const SALVAR_MASTER: string = 'master/salvar';
export const RENOVAR_MASTER: string = 'revenda/master/renovar';
export const NOTIFICAR_MASTER: string = 'revenda/master/notificar';
export const RELEMBRAR_ACESSO: string = 'master/relembrarAcessos';

// -- Planos
export const CONSULTA_PLANO: string = 'revenda/planos';
export const SALVAR_PLANO: string = 'revenda/plano/salvar';
export const EXCLUIR_PLANO: string = 'revenda/plano/excluir';

// SUB-REVENDAS

export const CONSULTA_SUB_REVENDA: string = 'subRevendas';
export const TRANSFERIR_CREDITO: string = 'credito/transferir';

// ADMINISTRATIVO

// -- Revendas
export const CONSULTA_REVENDA: string = 'revendas';
export const SALVAR_REVENDA: string = 'revenda/salvar';
export const EXCLUIR_REVENDA: string = 'revenda/excluir';
export const ADICIONAR_CREDITO: string = 'revenda/adicionarCredito';
export const CONSULTA_CREDITO_SOLICITADO: string = 'creditos';
export const SOLICITAR_CREDITO: string = 'credito/solicitar';

// SESSÃO WHATSAPP
export const VERIFICA_STATUS: string = 'whatsapp/verificaStatus';

// USUÁRIO
export const CONSULTA_USUARIO: string = 'usuario';
export const SALVAR_USUARIO: string = 'usuario/salvar';

// PARÂMETROS
export const CONSULTA_PARAMETROS: string = 'master/parametros';
export const SALVAR_PARAMETROS: string = 'master/parametros/salvar';

// AUXILIARES
export const HORA_SERVIDOR: string = 'painel/horaServidor';
export const RENOVACAO_EXTERNA: string = 'master/renovar';

// FINANCEIRO

// Contas Receber
export const CONSULTA_CONTAS_RECEBER = 'contasReceber';
export const SALVAR_CONTAS_RECEBER = 'contasReceber/salvar';
export const EXCLUIR_CONTAS_RECEBER = 'contasReceber/excluir';
export const RECEBIMENTO = 'contasReceber/recebimento';

// Movimento Financeiro
export const CONSULTA_MOV_FINANCEIRO = 'movimentoFinanceiro';
export const SALVAR_MOV_FINANCEIRO = 'movimentoFinanceiro/salvar';
export const EXCLUIR_MOV_FINANCEIRO = 'movimentoFinanceiro/excluir';

// Master Adm
export const CONSULTA_SUPORTE_MASTER = 'painel/masters';

// Treino
export const CONSULTA_TREINO = 'treinosIA';
export const SALVAR_TREINO = 'treinoIA';
export const EXCLUIR_TREINO = 'treinoIA';

//Assistente
export const CONSULTA_ASSISTENTE = 'assistentes';
export const SALVAR_ASSISTENTE = 'assistente';
export const EXCLUIR_ASSISTENTE = 'assistente';

// AtendimentoIA

export const CONSULTA_ATENDIMENTOIA = 'atendimentosIA';
